import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from 'components/Layout/Layout';



const VisitsByDate = () => {
    const [data, setData] = useState<any[]>([]);
    const [date, setDate] = useState<string>(new Date().toISOString().split('T')[0]);
    const [city, setCity] = useState<string>(''); // Для хранения выбранного города
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);

    const fetchData = async () => {
        setLoading(true);
        setMessage(null);
        try {
            const cityParam = city ? `&city=${city}` : '';
            const response = await fetch(`${process.env.REACT_APP_API_URL}/migration/get-by-date?date=${date}${cityParam}`);
            const text = await response.text();
            if (response.ok) {
                try {
                    const result = JSON.parse(text);
                    setData(result);
                } catch (error) {
                    setMessage(text);
                    setData([]);
                }
            } else {
                console.error('Ошибка при получении данных:', response.statusText);
            }
        } catch (error) {
            console.error('Ошибка при запросе:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [date, city]); // Запрос обновляется при изменении даты или города

    const handleCityClick = (selectedCity: string) => {
        setCity(selectedCity === city ? '' : selectedCity); // Сбрасываем город, если он уже выбран
    };

    const handleResetCity = () => {
        setCity(''); // Сбрасываем фильтр по городу
    };

    return (
        <Layout>
            <div>
                <h1>Данные о миграции</h1>
                <label>
                    Выберите дату:
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />
                </label>
                
                {city && (
                    <button onClick={handleResetCity} style={{ marginLeft: '10px' }}>
                        Сбросить фильтр по городу
                    </button>
                )}

                {loading ? (
                    <p>Загрузка...</p>
                ) : message ? (
                    <p>{message}</p>
                ) : (
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>IP адрес</th>
                                <th>Город</th>
                                <th>Дата создания</th>
                                <th>Детали</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.ip_address}</td>
                                    <td>
                                        <span
                                            style={{ color: 'blue', cursor: 'pointer' }}
                                            onClick={() => handleCityClick(item.city)}
                                        >
                                            {item.city}
                                        </span>
                                    </td>
                                    <td>{new Date(item.creation_date).toLocaleString('ru-RU', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                    })}</td>
                                    <td>
                                        <Link to={`/visit-details/${item.id}?date=${date}&ip=${item.ip_address}`}>Детали</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </Layout>
    );
};

export default VisitsByDate;