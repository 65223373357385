import React, { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Layout from "../Layout/Layout";
import { useUpdateArticle } from "../../hooks/articles/useUpdateArticle";
import { useFetchArticleById } from "../../hooks/articles/useFetchArticleById";
import { Link, useParams } from "react-router-dom";
import { FormRow } from "../fragment/FormRow";
import { useCategories } from "../../hooks/categories/useCategories";
import { Category } from "../../types/Category";

const UpdateArticleForm: React.FC = () => {
    const { articleId } = useParams<{ articleId: string }>();
    const [initialData, setInitialData] = useState<any | null>(null);
    const [imageToRemove, setImageToRemove] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [deleteImg, setDeleteImg] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const { data: article, isLoading: isFetching } = useFetchArticleById(Number(articleId));
    const { mutate: submitArticle, isLoading, error, isSuccess } = useUpdateArticle();
    const { data: categories } = useCategories();

    const renderCategoryOptions = (categories: Category[], level = 0): React.ReactNode => {
        return categories.map((category) => (
            <React.Fragment key={category.id}>
                <option value={category.id}>
                    {'-'.repeat(level)} {category.categoryName}
                </option>
                {category.children && renderCategoryOptions(category.children, level + 1)}
            </React.Fragment>
        ));
    };

    useEffect(() => {
        if (article) {
            const selectedCategoryIds = article.categories.map((category: any) => category.category.id);
            setInitialData({
                title: article.title || '',
                name: article.name || '',
                slug: article.slug || '',
                shortDescription: article.shortDescription || '',
                detailedDescription: article.detailedDescription || '',
                metadescription: article.metadescription || '',
                imageUrl: article.imageUrl,
                userId: article.userId || parseInt(localStorage.getItem('userId') || '1', 10),
                isActive: article.isActive || true,
                sortOrder: article.sortOrder || 0,
                categories: selectedCategoryIds
            });
        }
    }, [article]);

    if (isFetching) return <div>Loading...</div>;

    return (
        <Layout>
            <div>
                <h1>Обновить статью: {article.name}</h1>
                <Link to="/all-articles">Все статьи</Link>
                {initialData ? (
                    <Formik
                        initialValues={initialData}
                        enableReinitialize
                        validationSchema={Yup.object({
                            title: Yup.string().required('Required'),
                            name: Yup.string().required('Required'),
                            shortDescription: Yup.string().required('Required'),
                            detailedDescription: Yup.string().required('Required'),
                            metadescription: Yup.string(),
                            imageUrl: Yup.mixed().nullable(),
                            userId: Yup.number().required('Required'),
                            isActive: Yup.boolean(),
                            sortOrder: Yup.number(),
                            categories: Yup.array().of(Yup.number())
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            const formData = new FormData();
                            const articleData = {
                                id: Number(articleId),
                                title: values.title,
                                name: values.name,
                                slug: values.slug,
                                shortDescription: values.shortDescription,
                                detailedDescription: values.detailedDescription,
                                metadescription: values.metadescription,
                                userId: values.userId,
                                isActive: values.isActive,
                                sortOrder: values.sortOrder,
                                categories: values.categories,
                                imageToRemove // Передаём состояние удаления изображения
                            };

                            formData.append('articleData', JSON.stringify(articleData));
                            if (values.imageUrl) {
                                formData.append('image', values.imageUrl);
                            }

                            // console.log(JSON.stringify(articleData));
                            // Вывод содержимого formData
                            formData.forEach((value, key) => {
                                console.log(key + ": " + value);
                            });

                            submitArticle(formData);
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting, setFieldValue }) => (
                            <Form>
                                <>
                                    <table className="table">
                                        <tbody>
                                            <FormRow label="Название" type="text" name="name" placeholder="Введите имя" />
                                            <FormRow label="Slug" type="text" name="slug" placeholder="slug" />
                                            <tr>
                                                <td className="col-4">Категория товара</td>
                                                <td className="col-8">
                                                    <Field as="select" name="categories" multiple className="form-control form-control-lg">
                                                        <option value="" disabled>Выберите категории</option>
                                                        {categories && renderCategoryOptions(categories)}
                                                    </Field>
                                                </td>
                                            </tr>
                                            <FormRow
                                                label="Короткое описание"
                                                type="textarea"
                                                name="shortDescription"
                                                placeholder="Enter short description"
                                                showHtmlToggle={true}
                                            />
                                            <FormRow
                                                label="Детальное описание"
                                                type="textarea"
                                                name="detailedDescription"
                                                placeholder="Enter detailed description"
                                                showHtmlToggle={true} // Включаем переключение между визуальным и HTML-редактором
                                            />
                                            <FormRow label="Meta Title" type="text" name="title" placeholder="Enter title" />
                                            <FormRow label="Meta Description" type="text" name="metadescription" placeholder="Enter meta description" />
                                            <tr>
                                                <td className="col-4">Изображения</td>
                                                <td className="col-8">
                                                    {(previewImage || initialData.imageUrl) && !imageToRemove && (
                                                        <div>
                                                            <img src={previewImage || initialData.imageUrl} alt="Current" style={{ width: '200px' }} />
                                                            <button type="button" onClick={() => {
                                                                setImageToRemove(true);
                                                                setPreviewImage(null);
                                                                setFieldValue('imageUrl', null);
                                                                if (fileInputRef.current) {
                                                                    fileInputRef.current.value = '';
                                                                }
                                                            }}>Удалить</button>
                                                        </div>
                                                    )}
                                                    <input
                                                        ref={fileInputRef}
                                                        className="form-control form-control-lg"
                                                        type="file"
                                                        name="imageUrl"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            const file = e.currentTarget.files?.[0];
                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setPreviewImage(reader.result as string);
                                                                };
                                                                reader.readAsDataURL(file);
                                                                setFieldValue("imageUrl", file);
                                                                setImageToRemove(false);
                                                            } else {
                                                                setFieldValue("imageUrl", null);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button type="submit" disabled={isSubmitting || isLoading}>Submit</button>
                                    {error && <div>An error occurred: {(error as any).message}</div>}
                                    {isSuccess && <div>Article updated successfully!</div>}
                                </>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <p>Loading article data...</p>
                )}
            </div>
        </Layout>
    );
};

export default UpdateArticleForm;
