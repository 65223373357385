import React, { useRef, useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { Field, ErrorMessage, useFormikContext } from "formik";
import "react-quill/dist/quill.snow.css"; // Подключение CSS для Quill

// Определение кастомного формата "Bold"
const Inline = Quill.import('blots/inline');
class CustomBold extends Inline {
    static blotName = 'bold';
    static tagName = 'span';

    static create() {
        let node = super.create();
        node.style.fontWeight = 'bold'; // Устанавливаем стиль жирного текста
        return node;
    }

    static formats(node: HTMLElement) {
        return node.style.fontWeight === 'bold' ? 'bold' : undefined;
    }

    format(name: string, value: any) {
        if (name === 'bold' && value) {
            this.domNode.style.fontWeight = 'bold';
        } else {
            this.domNode.style.fontWeight = '';
        }
    }
}

Quill.register(CustomBold, true);

class CustomItalic extends Inline {
    static blotName = 'italic';
    static tagName = 'span';

    static create() {
        let node = super.create();
        node.style.fontStyle = 'italic'; // Устанавливаем стиль для курсива
        return node;
    }

    static formats(node: HTMLElement) {
        return node.style.fontStyle === 'italic' ? 'italic' : undefined;
    }

    format(name: string, value: any) {
        if (name === 'italic' && value) {
            this.domNode.style.fontStyle = 'italic';
        } else {
            this.domNode.style.fontStyle = '';
        }
    }
}

Quill.register(CustomItalic, true);

class CustomUnderline extends Inline {
    static blotName = 'underline';
    static tagName = 'span';

    static create() {
        let node = super.create();
        node.style.textDecoration = 'underline'; // Устанавливаем стиль для подчеркивания
        return node;
    }

    static formats(node: HTMLElement) {
        return node.style.textDecoration === 'underline' ? 'underline' : undefined;
    }

    format(name: string, value: any) {
        if (name === 'underline' && value) {
            this.domNode.style.textDecoration = 'underline';
        } else {
            this.domNode.style.textDecoration = '';
        }
    }
}

Quill.register(CustomUnderline, true);


class CustomStrike extends Inline {
    static blotName = 'strike';
    static tagName = 'span';

    static create() {
        let node = super.create();
        node.style.textDecoration = 'line-through'; // Устанавливаем стиль для зачеркнутого текста
        return node;
    }

    static formats(node: HTMLElement) {
        return node.style.textDecoration === 'line-through' ? 'strike' : undefined;
    }

    format(name: string, value: any) {
        if (name === 'strike' && value) {
            this.domNode.style.textDecoration = 'line-through';
        } else {
            this.domNode.style.textDecoration = '';
        }
    }
}

Quill.register(CustomStrike, true);




type FormRowProps = {
    label: string;
    type: string;
    name: string;
    placeholder: string;
    as?: string;
    className?: string;
    showHtmlToggle?: boolean; // Новое свойство для показа переключателя режимов
};

export const FormRow: React.FC<FormRowProps> = ({ label, type, name, placeholder, as, className = "form-control form-control-lg", showHtmlToggle = false }) => {
    const quillRef = useRef<any>(null);
    const { setFieldValue, values } = useFormikContext<any>();

    // Состояние для режима редактора: визуальный или HTML-код
    const [isHtmlMode, setIsHtmlMode] = useState(false);
    const [htmlContent, setHtmlContent] = useState(values[name] || "");

    // Функция для очистки HTML-контента
    const cleanHtmlContent = (content: string) => {
        return content
            .replace(/<p><br><\/p>/g, "") // Удаление пустых абзацев
            .replace(/(\r\n|\n|\r){2,}/g, "\n") // Удаление лишних строк
            .replace(/&nbsp;/g, " "); // Удаление &nbsp;
    };

    useEffect(() => {
        if (!quillRef.current) return;

        const quill = quillRef.current.getEditor();

        const handleTextChange = () => {
            let content = quill.root.innerHTML;
            content = cleanHtmlContent(content); // Очищаем HTML-контент перед сохранением
            setFieldValue(name, content);
            setHtmlContent(content);
        };

        quill.on("text-change", handleTextChange);

        return () => {
            quill.off("text-change", handleTextChange);
        };
    }, [name, setFieldValue]);

    const handleHtmlChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let content = e.target.value;
        content = cleanHtmlContent(content); // Очищаем HTML-контент перед сохранением
        setHtmlContent(content);
        setFieldValue(name, content);
    };

    return (
        <tr>
            <td className="col-4">{label}</td>
            <td className="col-8">
                {/* Показываем кнопку для переключения только если showHtmlToggle === true */}
                {showHtmlToggle && (
                    <div>
                        <button
                            type="button"
                            onClick={() => setIsHtmlMode(!isHtmlMode)}
                            className="btn btn-secondary mb-2"
                        >
                            {isHtmlMode ? "Переключиться на визуальный редактор" : "Переключиться на HTML-код"}
                        </button>
                    </div>
                )}
                {type === "textarea" ? (
                    isHtmlMode ? (
                        <textarea
                            className={className}
                            value={htmlContent}
                            onChange={handleHtmlChange}
                            placeholder={placeholder}
                            rows={10}
                        />
                    ) : (
                        <ReactQuill
                            ref={quillRef}
                            value={values[name]}
                            onChange={(value: string) => {
                                const cleanedValue = cleanHtmlContent(value);
                                setFieldValue(name, cleanedValue);
                                setHtmlContent(cleanedValue);
                            }}
                            theme="snow"
                            placeholder={placeholder}
                            formats={[
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'header',              // Заголовки
                                'list',                // Списки
                                'script',              // Верхний и нижний индекс
                                'indent',              // Отступы
                                'direction',           // Направление текста
                                'size',                // Размер шрифта
                                'color',               // Цвет текста
                                'background',          // Цвет фона
                                'font',                // Шрифт
                                'align'
                            ]} // Поддерживаем формат bold
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'],        // Жирный, курсив, подчеркивание, зачёркнутый
                                    [{ 'header': 1 }, { 'header': 2 }],               // Заголовки
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],     // Нумерованные и маркированные списки
                                    [{ 'script': 'sub' }, { 'script': 'super' }],      // Верхний и нижний индекс
                                    [{ 'indent': '-1' }, { 'indent': '+1' }],          // Вложенность
                                    [{ 'direction': 'rtl' }],                         // Направление текста
                                    [{ 'size': ['small', false, 'large', 'huge'] }],  // Размер текста
                                    [{ 'color': [] }, { 'background': [] }],          // Цвет текста и фона
                                    [{ 'font': [] }],                                 // Шрифты
                                    [{ 'align': [] }],                                // Выравнивание
                                    ['clean']                                         // Очистка форматирования
                                ]
                            }}
                        />


                    )
                ) : (
                    <Field
                        className={className}
                        type={type !== "textarea" ? type : undefined}
                        name={name}
                        placeholder={placeholder}
                        as={as}
                    />
                )}
                <ErrorMessage name={name} component="div" className="text-danger" />
            </td>
        </tr>
    );
};
