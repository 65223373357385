import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useCreateProduct } from 'hooks/products/useCreateProduct';
import Layout from 'components/Layout/Layout';
import { useCategories } from 'hooks/categories/useCategories';
import { Category } from 'types/Category';
import { useGetAllAttributes } from 'hooks/products/useGetAllAttributes';
import { Attribute } from 'types/ProductTypes';
import AttributeFields from './attributes/AttributeFields';
import { Link, useNavigate } from 'react-router-dom';
import { useAlertContent } from 'context/AlertContext';
import { Watch } from 'react-loader-spinner';
import { AxiosError } from 'axios';
import { number } from "yup";
import { userStore } from "../../stores/UserStore";
import { storeStore } from "../../stores/StoreStore";
import { observer } from 'mobx-react';
import { FormRow } from 'components/fragment/FormRow';

interface ErrorField {
    field: string;
    message: string;
}

interface ErrorResponseData {
    errorMessage: string;
    // Добавьте сюда любые другие поля, которые могут быть в вашем ответе ошибки
}

interface ErrorResponse {
    response: {
        status: number;
        data: ErrorResponseData;
    }
}

const CreateProductForm = observer(() => {
    const userId = parseInt(localStorage.getItem('userId') || '0', 10);
    const { mutate: createProduct, isLoading: isLoadingProduct } = useCreateProduct();
    const { data: categories } = useCategories();
    const { data: attributes, error: errorAttributes, isLoading: isLoadingAttributes } = useGetAllAttributes();
    const navigate = useNavigate();

    const { triggerAlert } = useAlertContent();
    // console.log("attributes: ");
    // console.dir(attributes);


    const [fileData, setFileData] = useState<FileList | null>(null);

    const [mainImage, setMainImage] = useState<File | null>(null);

    const [activeTab, setActiveTab] = useState('characteristics');

    const handleTabClick = (tabName: React.SetStateAction<string>) => {
        setActiveTab(tabName);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFileData(event.target.files);
        }
    };

    useEffect(() => {
        // const fetchData = async () => {
        //     const user = await storeStore.fetchStoreByUserId();
        //     console.log("user");
        //     console.log(user);
        // };
        //
        // fetchData();
        storeStore.fetchStoreByUserId();
    }, []);

    const renderCategoryOptions = (categories: Category[], level = 0): React.ReactNode => {
        return categories.map((category) => (
            <React.Fragment key={category.id}>
                <option value={category.id}>
                    {'-'.repeat(level)} {category.categoryName}
                </option>
                {category.children && renderCategoryOptions(category.children, level + 1)}
            </React.Fragment>
        ));
    };

    // Функция для рендера опций магазинов
    const renderStoreOptions = () => {
        return storeStore.stores.map((store, index) => (
            <option key={index} value={store.id}>{store.storeName}</option>
        ));
    };

    return (
        <Layout>
            <div>
                <h2>Создание товара</h2>
                <Link to="/all-product">Все товары</Link>
                <Formik
                    initialValues={{
                        productname: '',
                        storeId: '',
                        is_active: true,
                        sort_order: 0,
                        userId: userId, // Замените на актуальный UserID, полученный откуда-то еще
                        categoryIds: [],
                        attributeIds: [],
                        price: number,
                        description: '',
                        seoTitle: '',
                        seoDescription: '',
                        attributeValues: attributes ? attributes.map(attribute => ({
                            attributeId: attribute.id,
                            value: '',
                        })) : [],
                    }}


                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                        const formData = new FormData();

                        // Трансформация attributeValues, гарантируя, что каждый объект содержит свойство 'value'
                        const transformedAttributeValues = values.attributeValues.map(attr => ({
                            ...attr,
                            value: attr.value ?? ''
                        }));

                        // const filteredAttribute = values.attributeValues.filter(item => "value" in item);
                        const filteredAttributeValues = values.attributeValues.filter((item) => item.value !== undefined && item.value !== "");

                        // Упаковываем все текстовые данные в один объект, уже с трансформированными attributeValues
                        const textData = {
                            productname: values.productname,
                            storeId: values.storeId,
                            is_active: values.is_active,
                            sort_order: values.sort_order,
                            userId: values.userId,
                            price: values.price,
                            description: values.description,
                            categoryIds: values.categoryIds,
                            seoTitle: values.seoTitle,
                            seoDescription: values.seoDescription,
                            // attributeValues: transformedAttributeValues, 
                            attributeValues: filteredAttributeValues,
                        };

                        console.log("values.attributeValues");
                        console.log(values.attributeValues);
                        console.log('filteredAttributeValues');
                        console.log(filteredAttributeValues);


                        // Добавляем сериализованный JSON текстовых данных
                        formData.append('productData', JSON.stringify(textData));


                        // Добавляем файлы
                        if (fileData) {
                            for (let i = 0; i < fileData.length; i++) {
                                formData.append('images', fileData[i]);
                            }
                        }

                        if (mainImage) {
                            formData.append('mainImage', mainImage);
                        }

                        // Логирование содержимого useFormData для отладки
                        for (let [key, value] of formData.entries()) {
                            console.log(`${key}: ${value}`);
                        }
                        // Отправка useFormData на сервер
                        createProduct(formData, {
                            onSuccess: (data) => {
                                // alert("Product created successfully!");
                                triggerAlert('Товар успешно создан!', 'success');
                                const productId = data.data.id; // Предполагается, что возвращается ID созданного продукта
                                // history.push(`/update-product/${productId}`);
                                navigate(`/update-product/${productId}`);
                                console.log(data.data.id);


                                // Очистка формы или перенаправление пользователя может быть здесь
                            },
                            onError: (error: unknown) => {
                                console.error(error);
                                const axiosError = error as AxiosError<ErrorResponseData>;

                                if (axiosError.response && axiosError.response.status === 400) {
                                    const errorMessage = axiosError.response.data as unknown as string ?? 'Unknown error';


                                    if (typeof errorMessage === 'string' && errorMessage.includes("Product name or slug already exists")) {
                                        setFieldError("productname", errorMessage as string);
                                        console.log(errorMessage);
                                    }


                                }
                            }

                        });

                        setSubmitting(false);
                    }}


                >
                    {({ isSubmitting, errors, touched, setFieldError }) => (
                        <Form>
                            <div>
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${activeTab === 'characteristics' ? 'active' : ''}`}
                                            onClick={() => handleTabClick('characteristics')}
                                        >
                                            Характеристики
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${activeTab === 'attributes' ? 'active' : ''}`}
                                            onClick={() => handleTabClick('attributes')}
                                        >
                                            Атрибуты
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${activeTab === 'seo' ? 'active' : ''}`}
                                            onClick={() => handleTabClick('seo')}
                                        >
                                            Seo
                                        </a>
                                    </li>
                                </ul>


                                <div className="tab-content">

                                    <div className={`tab-pane ${activeTab === 'characteristics' ? 'show active' : ''}`}>
                                        <table className="table">
                                            <tbody>

                                                <tr>
                                                    <td className="col-4">Магазин</td>
                                                    <td className="col-8">
                                                        <Field as="select" name="storeId" className="form-control form-control-lg">
                                                            <option value="" disabled>Выберите магазин</option>
                                                            {renderStoreOptions()}
                                                        </Field>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>Активный</td>
                                                    <td>
                                                        <div className="form-check form-switch">
                                                            <Field
                                                                name="is_active"
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="is_active"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="col-4">Основное изображение</td>
                                                    <td className="col-8">
                                                        <input
                                                            className="form-control form-control-lg"
                                                            type="file"
                                                            name="mainImage"
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (event.target.files && event.target.files[0]) {
                                                                    setMainImage(event.target.files[0]);
                                                                }
                                                            }}
                                                        />

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="col-4">Название товара</td>
                                                    <td className="col-8">
                                                        <Field
                                                            className={`form-control form-control-lg ${errors.productname && touched.productname ? 'is-invalid' : ''}`}
                                                            type="text"
                                                            name="productname"
                                                            placeholder="Название товара" />
                                                        <ErrorMessage name="productname" component="div" className="error" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="col-4">Изображения товара</td>
                                                    <td className="col-8">
                                                        <input
                                                            className="form-control form-control-lg"
                                                            type="file"
                                                            name="images"
                                                            onChange={handleFileChange}
                                                            multiple
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="col-4">Категория товара</td>
                                                    <td className="col-8">
                                                        <Field as="select" name="categoryIds" multiple
                                                            className="form-control form-control-lg">
                                                            <option value="" disabled>Выберите категории</option>
                                                            {categories && renderCategoryOptions(categories)}
                                                        </Field>
                                                    </td>
                                                </tr>

                                                {/* <tr>
                                                <td className="col-4">Описание товара</td>
                                                <td className="col-8">
                                                    <Field
                                                        className="form-control form-control-lg"
                                                        as="textarea"
                                                        name="description"
                                                        placeholder="Описание товара"/>
                                                </td>
                                            </tr> */}

                                                <FormRow
                                                    label="Описание товара"
                                                    type="textarea"
                                                    name="description"
                                                    placeholder="Описание товара"
                                                    showHtmlToggle={true} // Включаем переключение между визуальным и HTML-редактором
                                                />

                                                <tr>
                                                    <td className="col-4">Сортировка</td>
                                                    <td className="col-8">
                                                        <Field className="form-control form-control-lg" type="text"
                                                            name="sort_order" placeholder="Сортировка" />
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td className="col-4">Цена</td>
                                                    <td className="col-8">
                                                        <Field className="form-control form-control-lg" type="number"
                                                            name="price" placeholder="Цена" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className={`tab-pane ${activeTab === 'attributes' ? 'show active' : ''}`}>
                                        <table className="table">
                                            <tbody>

                                                <div className="fw-bold fs-3">Атрибуты товара</div>
                                                <AttributeFields attributes={attributes || []} />
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'seo' ? 'show active' : ''}`}>
                                        <table className="table">
                                            <tbody>

                                                <tr>
                                                    <td className="col-4">Title</td>
                                                    <td className="col-8">
                                                        <Field className="form-control form-control-lg" type="text"
                                                            name="seoTitle" placeholder="seoTitle" />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="col-4">Description</td>
                                                    <td className="col-8">
                                                        <Field className="form-control form-control-lg" type="text"
                                                            name="seoDescription" placeholder="Description" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>


                            <button className="btn btn-primary" type="submit" /*disabled={isSubmitting}*/>
                                Создать товар
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
            {(isLoadingProduct || isLoadingAttributes) && (
                <div className="loader-spinner">
                    <Watch
                        visible={true}
                        height="80"
                        width="80"
                        radius="48"
                        color="#4fa94d"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
        </Layout>
    );
});

export default CreateProductForm;
