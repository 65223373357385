import React, {ChangeEvent, useEffect, useState} from 'react';
import Layout from 'components/Layout/Layout';
import useUser from 'hooks/useUser';
import useHandleInputChange from 'hooks/useHandleInputChange'; // Импортируйте новый хук
import useAlert from 'hooks/useAlert';
import {Formik, Form, Field} from 'formik';
import {User} from 'types/User';
import InputMask from 'react-input-mask';
import {useSubmitUserFieldValues} from 'hooks/userFields/useSubmitUserFieldValues';
import {useUpdateUserFieldValues} from 'hooks/user/updateUserFieldValues';
import {useFetchUserFieldsForUser} from 'hooks/userFields/useFetchUserFieldsForUser';
import {useSaveUserFieldValues} from 'hooks/userFields/useSaveUserFieldValues';
import {UserFieldValue} from 'types/UserFieldValue';
import {Watch} from 'react-loader-spinner';
import {getInitialValues} from 'hooks/user/getInitialValues';
import GlowingButton from "../fragment/GlowingButton/GlowingButton";

const Index: React.FC = () => {
    // const userId = useSelector((state: RootState) => state.user.userId);
    const userId = localStorage.getItem('userId') || '';
    const userIdNum = +userId;
    // console.log("Index: " + userId);


    // const { user, setUser, isLoading, error, refetch: userRefetch } = useUser(userId);


    const {user, isLoading, error, updateUser, refetch: userRefetch} = useUser(userId);

    // const initialValues = getInitialValues(user);


    console.dir(user)
    // const handleInputChange = useHandleInputChange(setUser); // Используйте хук

    const saveUserFieldValues = useSaveUserFieldValues();

    const {
        data: userFields,
        isLoading: isUserFieldsLoading,
        isError: isUserFieldsError,
        error: userFieldsError,
        refetch: userFieldsRefetch
    } = useFetchUserFieldsForUser();
    const [userFieldValues, setUserFieldValues] = useState<UserFieldValue[]>([]);
    const [formUpdated, setUserFormUpdated] = useState<boolean>(false);


    useEffect(() => {
        if (userFields) {
            const initialValues = userFields.map(field => ({
                userId: parseInt(localStorage.getItem('userId') || '0'),
                fieldId: field.id,
                value: '',
                isActive: field.isActive
            }));
            setUserFieldValues(initialValues);
        }
        // }, [userFields, userFieldValues]);
    }, [userFields]);


    const [initialValues, setInitialValues] = useState<User>({
        id: 0,
        role_id: 0,
        firstname: '',
        lastname: '',
        patronymic: '',
        phone: '',
        email: '',
        password: '',
        infos: [],
        username: '',
        is_active: true,
        is_registered: true,
        userFieldValues: [],
        articles: []
    });


    useEffect(() => {
        // Загрузка данных продукта и установка их как начальных значений
        const fetchData = async () => {
            if (user) {
                setInitialValues({
                    id: user?.id || 0,
                    role_id: user?.role_id || 0,
                    firstname: user?.firstname || '',
                    lastname: user?.lastname || '',
                    patronymic: user?.patronymic || '',
                    phone: user?.phone || '',
                    email: user?.email || '',
                    password: user?.password || '',
                    infos: user?.infos || [],
                    username: user?.username || '',
                    is_active: user?.is_active || true,
                    is_registered: user?.is_registered || true,
                    userFieldValues: user?.userFieldValues || [],
                    articles: user?.articles || []
                });
            }
        };

        fetchData();
    }, [user]);

    const handleInputChangeuserFields = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {


        const fieldId = parseInt(event.target.name);
        const value = event.target.value;

        setUserFieldValues(userFieldValues.map((fieldValue: UserFieldValue) => // Явное указание типа
            fieldValue.fieldId === fieldId ? {...fieldValue, value: value} : fieldValue
        ));
        // console.log("value: " + value);
    };

    const mapFieldTypeToInputType = (fieldType: string): string => {
        const typesMap: Record<string, string> = {
            'Строка': 'text',
            'Число': 'number',
            'Дата': 'date',
            'email': 'email', // Добавляем сюда, если 'email' является одним из возможных значений
            // другие возможные сопоставления
        };

        // Проверяем, есть ли такой ключ в typesMap, иначе возвращаем 'text'
        return typesMap[fieldType] || 'text';
    };

    // console.log("userFields");
    // console.dir(userFields);
    // console.log("useFetchUserFieldsForUser END");

    // console.dir(user?.userFieldValues);
    // console.dir(user);

    // Получаем функцию мутации
    const {mutate: submitUserFieldValues, isLoading: isLoadingUserFieldValues} = useUpdateUserFieldValues(userIdNum);

    const {alertData, showAlert, triggerAlert, resetAlert} = useAlert();

    // const createUserFieldMutation = useSubmitUserFieldValues(triggerAlert);
    const createUserFieldMutation = useSubmitUserFieldValues();

    const handleSubmit = async (values: User) => {
        // console.log("userFieldValues");
        // console.dir(userFieldValues);

        createUserFieldMutation.mutateAsync(userFieldValues)
        submitUserFieldValues(values);
        setIsEditing(false);
        setUserFormUpdated(true);
    };

    const [isEditing, setIsEditing] = useState(false); // Добавленное состояние для редактирования

    const handleEditClick = () => {
        setIsEditing(!isEditing); // Переключение режима редактирования
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <Layout>
            <a className="editUser link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
               onClick={handleEditClick}>
                <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17.0671 2.27157C17.5 2.09228 17.9639 2 18.4324 2C18.9009 2 19.3648 2.09228 19.7977 2.27157C20.2305 2.45086 20.6238 2.71365 20.9551 3.04493C21.2864 3.37621 21.5492 3.7695 21.7285 4.20235C21.9077 4.63519 22 5.09911 22 5.56761C22 6.03611 21.9077 6.50003 21.7285 6.93288C21.5492 7.36572 21.2864 7.75901 20.9551 8.09029L20.4369 8.60845L15.3916 3.56308L15.9097 3.04493C16.241 2.71365 16.6343 2.45086 17.0671 2.27157Z"
                        fill="#000000"/>
                    <path
                        d="M13.9774 4.9773L3.6546 15.3001C3.53154 15.4231 3.44273 15.5762 3.39694 15.7441L2.03526 20.7369C1.94084 21.0831 2.03917 21.4534 2.29292 21.7071C2.54667 21.9609 2.91693 22.0592 3.26314 21.9648L8.25597 20.6031C8.42387 20.5573 8.57691 20.4685 8.69996 20.3454L19.0227 10.0227L13.9774 4.9773Z"
                        fill="#545e69"/>
                </svg>
                {isEditing ? 'Сохранить' : 'Редактировать'}
            </a>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(values) => handleSubmit(values)}
            >
                {({values, handleChange, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>

                        <table className="table align-middle mb-0 bg-white">
                            <thead className="bg-light">
                            <tr>
                                <th className="col-4">Свойство</th>
                                <th className="col-8">Значение</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="col-4">Фамилия</td>
                                <td className="col-8">
                                    {isEditing ? (
                                        <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            name="lastname"
                                            placeholder="Введите свою Фамилию"
                                            value={values.lastname}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <span>{values.lastname}</span>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-4">Имя</td>
                                <td className="col-8">
                                    {isEditing ? (
                                        <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            name="firstname"
                                            placeholder="Введите свое имя"
                                            value={values.firstname}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <span>{values.firstname}</span>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-4">Отчество</td>
                                <td className="col-8">
                                    {isEditing ? (
                                        <input
                                            className="form-control form-control-lg"
                                            type="text"
                                            name="patronymic"
                                            placeholder="Введите своё отчество"
                                            value={values.patronymic}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <span>{values.patronymic}</span>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-4">Телефон</td>
                                <td className="col-8">
                                    {isEditing ? (
                                        <InputMask
                                            mask="+7 (999) 999-99-99"
                                            className="form-control form-control-lg"
                                            type="tel"
                                            name="phone"
                                            placeholder="Введите свой телефон"
                                            value={values.phone}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <span>{values.phone}</span>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-4">Email</td>
                                <td className="col-8">
                                    {isEditing ? (
                                        <input
                                            className="form-control form-control-lg"
                                            type="email"
                                            name="email"
                                            placeholder="Введите свой Email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <span>{values.email}</span>
                                    )}
                                </td>
                            </tr>
                            {(userFields || []).map((field, index) => (
                                <tr key={field.id}>
                                    <td className="col-4">{field.name}</td>
                                    <td className="col-8">
                                        {isEditing ? (
                                            field.type === "Список" ? (
                                                <select
                                                    className="form-control form-control-lg"
                                                    name={field.id.toString()}
                                                    onChange={handleInputChangeuserFields}
                                                >
                                                    <option value="">{`Выберите`}</option>
                                                    {field.typeValue.split(', ').map((option, optionIndex) => (
                                                        <option key={optionIndex} value={option}>{option}</option>
                                                    ))}
                                                </select>
                                            ) : field.id === 1 ? (
                                                <InputMask
                                                    mask="999999999999"
                                                    className="form-control form-control-lg"
                                                    type="text"
                                                    name={field.id.toString()}
                                                    placeholder="Введите ИНН"
                                                    onChange={handleInputChangeuserFields}
                                                />
                                            ) : (
                                                <input
                                                    className="form-control form-control-lg"
                                                    type={mapFieldTypeToInputType(field.type)}
                                                    name={field.id.toString()}
                                                    placeholder={field.name}
                                                    onChange={handleInputChangeuserFields}
                                                />
                                            )
                                        ) : (
                                            <span>{/* Значение для не редактируемого режима */}</span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            {/* Динамически создаем строки таблицы для дополнительных значений */}

                            {user?.userFieldValues.map((fieldValue, index) => (
                                <tr key={index}>
                                    <td className="col-4">{values.userFieldValues[index]?.field.name || ''}</td>
                                    <td className="col-8">
                                        {isEditing ? (
                                            values.userFieldValues[index]?.field.type === "Список" ? (
                                                <select
                                                    className="form-control form-control-lg"
                                                    name={`userFieldValues.${index}.value`}
                                                    value={values.userFieldValues[index]?.value || ''}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">{`Выберите`}</option>
                                                    {values.userFieldValues[index]?.field.typeValue?.split(', ').map((option, optionIndex) => (
                                                        <option key={optionIndex}
                                                                value={option.trim()}>{option.trim()}</option>
                                                    ))}
                                                </select>
                                            ) : values.userFieldValues[index]?.field.id === 1 ? (
                                                <InputMask
                                                    mask="999999999999"
                                                    className="form-control form-control-lg"
                                                    type="text"
                                                    name={`userFieldValues.${index}.value`}
                                                    placeholder="Введите ИНН"
                                                    value={values.userFieldValues[index]?.value || ''}
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="text"
                                                    name={`userFieldValues.${index}.value`}
                                                    placeholder={`Введите ${values.userFieldValues[index]?.field.name}`}
                                                    value={values.userFieldValues[index]?.value || ''}
                                                    onChange={handleChange}
                                                />
                                            )
                                        ) : (
                                            <span>{values.userFieldValues[index]?.value || ''}</span>
                                        )}
                                    </td>
                                </tr>
                            ))}

                            </tbody>
                        </table>


                        <div className="block-button">
                            <button type="submit" className="btn btn-success">Обновить пользователя</button>
                        </div>

                    </form>
                )}
            </Formik>
            {isLoadingUserFieldValues && (
                <div className="loader-spinner">
                    <Watch
                        visible={true}
                        height="80"
                        width="80"
                        radius="48"
                        color="#4fa94d"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
            {/* <AlertMessage
                message={alertData.message}
                type={alertData.type}
                showAlert={showAlert}
                onClose={resetAlert}
            /> */}
        </Layout>
    );
};

export default Index;
