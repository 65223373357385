import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface BreathCircleProps {
    duration: number; // Продолжительность одного цикла дыхания (вдох и выдох) в секундах
}

const BreathCircle: React.FC<BreathCircleProps> = ({ duration }) => {
    const [isInhaling, setIsInhaling] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/login');
    }, [navigate]);

    useEffect(() => {
        // Запускаем интервал для смены состояния вдоха и выдоха
        const interval = setInterval(() => {
            setIsInhaling(!isInhaling);
        }, duration * 1000 / 2); // Половина времени на вдох, половина на выдох

        return () => clearInterval(interval);
    }, [isInhaling, duration]);

    // Стили для анимации
    const circleStyle: React.CSSProperties = {
        width: isInhaling ? '100px' : '10px',
        height: isInhaling ? '100px' : '10px',
        borderRadius: '50%',
        backgroundColor: 'blue',
        transition: `all ${duration / 2}s ease-in-out`,
    };

    return (
        <div>
            <Link to="/login">Login</Link >
            <div style={circleStyle} />
        </div>

    );
};

export default BreathCircle;