// import { makeAutoObservable, reaction } from 'mobx';
// import axios from 'axios';
// import { userStore } from './UserStore';
// import {Store} from "redux";
// import {StoreData} from "../types/StoreData";
//
// class StoreStore {
//     hasStore: boolean | null = null;
//     stores: StoreData[] = [];
//     loading: boolean = false;
//     error: string = '';
//     subscribers: (() => void)[] = [];
//
//     constructor() {
//         makeAutoObservable(this);
//     }
//
//     fetchUserHasStore = async (userId: number) => {
//         this.loading = true;
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}/stores/user/${userId}/hasStore`);
//             this.hasStore = response.data;
//             this.error = '';
//         } catch (error) {
//             this.error = 'Ошибка при проверке наличия магазинов';
//         } finally {
//             this.loading = false;
//         }
//     };
//
//     createStore = async (store: StoreData) => {  // Использование StoreData
//         this.loading = true;
//         const token = localStorage.getItem("token");
//         try {
//             const response = await axios.post(`${process.env.REACT_APP_API_URL}/stores`, store, {
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 }
//             });
//             console.log('Магазин успешно создан: ', response.data);
//             await this.fetchUserHasStore(response.data.userId);
//             this.error = '';
//         } catch (error) {
//             console.error('Ошибка при создании магазина: ', error);
//             this.error = 'Ошибка при создании магазина';
//         } finally {
//             this.loading = false;
//         }
//     };
//
//     fetchStoreByUserId = async () => {
//         await userStore.fetchUserByEmail();
//         const userId = userStore.user ? userStore.user.id : null;
//         if (userId === null) {
//             console.error('Ошибка: ID пользователя не найден');
//             return;
//         }
//         this.loading = true;
//         const token = localStorage.getItem("token");
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}/stores/user/${userId}`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 }
//             });
//             this.stores = response.data;  // Сохраняем полученные данные магазинов в stores
//             this.hasStore = response.data.length > 0;
//             console.log('Данные магазина получены: ', response.data);
//             this.error = '';
//         } catch (error) {
//             console.error('Ошибка при получении магазина: ', error);
//             this.error = 'Ошибка при получении магазина';
//         } finally {
//             this.loading = false;
//         }
//     };
//
//     updateStore = async (storeId: number, storeData: Partial<StoreData>) => {
//         this.loading = true;
//         const token = localStorage.getItem("token");
//         try {
//             const response = await axios.put(`${process.env.REACT_APP_API_URL}/stores/${storeId}`, storeData, {
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 }
//             });
//             console.log('Магазин успешно обновлён: ', response.data);
//             this.error = '';
//         } catch (error) {
//             console.error('Ошибка при обновлении магазина: ', error);
//             this.error = 'Ошибка при обновлении магазина';
//         } finally {
//             this.loading = false;
//         }
//     };
//
// }
// export const storeStore = new StoreStore();

import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { userStore } from "./UserStore";
import { StoreData } from "../types/StoreData";

class StoreStore {
  hasStore: boolean | null = null;
  stores: StoreData[] = [];
  currentStore: StoreData | null = null; // Добавляем новое свойство для хранения текущего магазина
  loading: boolean = false;
  loadingHasStore: boolean = false;
  error: string = "";
  subscribers: (() => void)[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async fetchUserHasStore(userId: number) {
    console.log("fetchUserHasStore");
    console.log(userId);
    this.setLoadingHasStore(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/stores/user/${userId}/hasStore`
      );
      runInAction(() => {
        this.hasStore = response.data;
        this.error = "";
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Ошибка при проверке наличия магазинов";
      });
    } finally {
      this.setLoadingHasStore(false);
    }
  }

  async createStore(store: StoreData) {
    this.setLoading(true);
    const token = localStorage.getItem("token");

    if (!token) {
      runInAction(() => {
        this.error = "Токен не найден";
        this.setLoading(false);
      });
      return;
    }
    console.log("store: " + JSON.stringify(store, null, 2));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/stores`,
        store,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      runInAction(() => {
        console.log("Магазин успешно создан: ", response.data);
        // Убедитесь, что userId доступен в response.data
        if (response.data.userId) {
          this.fetchUserHasStore(response.data.userId);
        }
      });
    } catch (error) {
      runInAction(() => {
        console.error("Ошибка при создании магазина: ", error);
        if (axios.isAxiosError(error)) {
          this.error =
            error.response?.data?.message || "Ошибка при создании магазина";
        } else {
          this.error = "Неизвестная ошибка при создании магазина";
        }
      });
    } finally {
      this.setLoading(false);
    }
  }

  // получить все магазины
  async fetchStoreByUserId() {
    // await userStore.fetchUserByEmail();
    // const userId = userStore.user ? userStore.user.id : null;
    // if (userId === null) {
    //     console.error('Ошибка: ID пользователя не найден');
    //     return;
    // }
    this.setLoading(true);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/stores/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      runInAction(() => {
        this.stores = response.data;
        // this.hasStore = response.data.length > 0;
        console.log("Данные магазина получены: ", response.data);
        this.error = "";
      });
    } catch (error) {
      runInAction(() => {
        console.error("Ошибка при получении магазина: ", error);
        this.error = "Ошибка при получении магазина";
      });
    } finally {
      this.setLoading(false);
    }
  }

  // получить один магазин
  async fetchStoreById(storeId: number) {
    this.setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get<StoreData>(
        `${process.env.REACT_APP_API_URL}/stores/${storeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      runInAction(() => {
        // Теперь TypeScript знает, что response.data соответствует типу StoreData
        this.currentStore = response.data;
        this.error = "";
      });
    } catch (error) {
      runInAction(() => {
        console.error("Ошибка при получении магазина: ", error);
        this.error = "Ошибка при получении магазина";
      });
    } finally {
      this.setLoading(false);
    }
  }

  async updateStore(storeId: number, storeData: Partial<StoreData>) {
    this.setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/stores/${storeId}`,
        storeData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      runInAction(() => {
        console.log("Магазин успешно обновлён: ", response.data);
        this.error = "";
      });
    } catch (error) {
      runInAction(() => {
        console.error("Ошибка при обновлении магазина: ", error);
        this.error = "Ошибка при обновлении магазина";
      });
    } finally {
      this.setLoading(false);
    }
  }

  private setLoading(value: boolean) {
    runInAction(() => {
      this.loading = value;
    });
  }

  private setLoadingHasStore(value: boolean) {
    runInAction(() => {
      this.loadingHasStore = value;
    });
  }
}

export const storeStore = new StoreStore();
