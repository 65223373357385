import React from 'react';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useSubmitArticle } from "../../hooks/articles/useSubmitArticle";
import Layout from "../Layout/Layout";
import { useFormData } from "../../utils/useFormData";
import { Category } from "../../types/Category";
import { useCategories } from "../../hooks/categories/useCategories";
import { FormRow } from "../fragment/FormRow";
import { generateSlug } from "../../utils/generateSlug";
import { Link } from "react-router-dom";


const CreateArticleForm: React.FC = () => {
    const { mutate: submitArticle, isLoading, error, isSuccess } = useSubmitArticle();
    const { createFormData } = useFormData();
    const { data: categories } = useCategories();
    const userId = localStorage.getItem('userId');


    const renderCategoryOptions = (categories: Category[], level = 0): React.ReactNode => {
        return categories.map((category) => (
            <React.Fragment key={category.id}>
                <option value={category.id}>
                    {'-'.repeat(level)} {category.categoryName}
                </option>
                {category.children && renderCategoryOptions(category.children, level + 1)}
            </React.Fragment>
        ));
    };

    return (
        <Layout>
            <div>
                <h1>Создать статью</h1>
                <Link to="/all-articles">Все статьи</Link>
                <Formik
                    initialValues={{
                        name: '',
                        slug: '',
                        title: '',
                        shortDescription: '',
                        detailedDescription: '',
                        metadescription: '',
                        imageUrl: undefined,
                        userId: userId ? parseInt(userId) : null,
                        isActive: true,
                        sortOrder: 0,
                        categories: [] as number[]
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Required'),
                        slug: Yup.string().required('Required'),
                        title: Yup.string().required('Required'),
                        shortDescription: Yup.string().required('Required'),
                        detailedDescription: Yup.string().required('Required'),
                        metadescription: Yup.string(),
                        imageUrl: Yup.mixed(),
                        userId: Yup.number().required('Required'),
                        isActive: Yup.boolean(),
                        sortOrder: Yup.number()
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                        console.log("values");
                        console.log(values);
                        const formData = new FormData();

                        // Создаем объект данных статьи, исключая imageUrl, если он не задан
                        const articleData = {
                            name: values.name,
                            slug: values.slug,
                            title: values.title,
                            shortDescription: values.shortDescription,
                            detailedDescription: values.detailedDescription,
                            metadescription: values.metadescription,
                            userId: values.userId,
                            isActive: values.isActive,
                            sortOrder: values.sortOrder,
                            categories: values.categories

                        };

                        formData.append('articleData', JSON.stringify(articleData)); // Изменяем, чтобы articleData был параметром формы, а не файлом
                        if (values.imageUrl) {
                            formData.append('image', values.imageUrl);
                        }

                        for (let [key, value] of formData.entries()) {
                            console.log(`${key}:`, value);
                        }
                        submitArticle(formData);
                    }}
                >
                    {({ values, setFieldValue, isSubmitting, handleChange }) => (
                        <Form>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="col-4">Название статьи</td>
                                        <td className="col-8">
                                            <Field
                                                className={`form-control form-control-lg`}
                                                type="text"
                                                name="name"
                                                placeholder="Название статьи"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleChange(e);
                                                    const { value } = e.target;
                                                    setFieldValue('name', value);
                                                    setFieldValue('slug', generateSlug(value));
                                                }}
                                            />
                                            <ErrorMessage name="name" component="div" className="error" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="col-4">Slug</td>
                                        <td className="col-8">
                                            <Field
                                                className={`form-control form-control-lg`}
                                                type="text"
                                                name="slug"
                                                placeholder="Slug"
                                            />
                                            <ErrorMessage name="slug" component="div" className="error" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="col-4">Категория товара</td>
                                        <td className="col-8">
                                            <Field as="select" name="categories" multiple
                                                className="form-control form-control-lg">
                                                <option value="" disabled>Выберите категории</option>
                                                {categories && renderCategoryOptions(categories)}
                                            </Field>
                                        </td>
                                    </tr>

                                    <FormRow label="Мета title" type="text" name="title"
                                        placeholder="Мета title" />
                                    <FormRow
                                        label="Короткое описание"
                                        type="textarea"
                                        name="shortDescription"
                                        placeholder="Enter short description"
                                        showHtmlToggle={true}
                                    />
                                    <FormRow
                                        label="Детальное описание"
                                        type="textarea"
                                        name="detailedDescription"
                                        placeholder="Enter detailed description"
                                        showHtmlToggle={true} // Включаем переключение между визуальным и HTML-редактором
                                    />
                                    <FormRow label="Мета описание" type="text" name="metadescription"
                                        placeholder="Мета описание" />
                                    <tr>
                                        <td className="col-4">Изображения</td>
                                        <td className="col-8">
                                            <input
                                                className="form-control form-control-lg"
                                                type="file"
                                                name="imageUrl"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const file = e.currentTarget.files?.[0];
                                                    setFieldValue("imageUrl", file);
                                                }}
                                                multiple
                                            />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <button type="submit" disabled={isSubmitting}>
                                Создать статью
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Layout>
    );
};

export default CreateArticleForm;


// const CreateArticleForm: React.FC = () => {
//     const {mutate: submitArticle, isLoading, error, isSuccess} = useSubmitArticle();
//     const { createFormData } = useFormData();
//     const {data: categories} = useCategories();
//     const renderCategoryOptions = (categories: Category[], level = 0): React.ReactNode => {
//         return categories.map((category) => (
//             <React.Fragment key={category.id}>
//                 <option value={category.id}>
//                     {'-'.repeat(level)} {category.categoryName}
//                 </option>
//                 {category.children && renderCategoryOptions(category.children, level + 1)}
//             </React.Fragment>
//         ));
//     };
//
//     const formik = useFormik({
//         initialValues: {
//             name: '',
//             title: '',
//             shortDescription: '',
//             detailedDescription: '',
//             metadescription: '',
//             imageUrl: undefined,
//             userId: parseInt(localStorage.getItem('userId') || '1', 10),
//             isActive: true,
//             sortOrder: 0
//         },
//         validationSchema: Yup.object({
//             name: Yup.string().required('Required'),
//             title: Yup.string().required('Required'),
//             shortDescription: Yup.string().required('Required'),
//             detailedDescription: Yup.string().required('Required'),
//             metadescription: Yup.string(),
//             imageUrl: Yup.mixed(),
//             userId: Yup.number().required('Required'),
//             isActive: Yup.boolean(),
//             sortOrder: Yup.number()
//         }),
//         onSubmit: (values) => {
//             console.log("values");
//             console.log(values);
//             const formData = new FormData();
//
//             // Создаем объект данных статьи, исключая imageUrl, если он не задан
//             const articleData = {
//                 name: values.name,
//                 title: values.title,
//                 shortDescription: values.shortDescription,
//                 detailedDescription: values.detailedDescription,
//                 metadescription: values.metadescription,
//                 userId: values.userId,
//                 isActive: values.isActive,
//                 sortOrder: values.sortOrder
//             };
//
//             formData.append('articleData', JSON.stringify(articleData)); // Изменяем, чтобы articleData был параметром формы, а не файлом
//             if (values.imageUrl) {
//                 formData.append('image', values.imageUrl);
//             }
//
//             for (let [key, value] of formData.entries()) {
//                 console.log(`${key}:`, value);
//             }
//             submitArticle(formData);
//
//             // const formData = createFormData(values);
//             // for (let [key, value] of formData.entries()) {
//             //     console.log(`${key}:`, value);
//             // }
//             // submitArticle(formData);
//         }
//     });
//
//     return (
//         <Layout>
//             <form onSubmit={formik.handleSubmit}>
//                 <>
//                     <div>
//                         <label htmlFor="name">name</label>
//                         <input
//                             id="name"
//                             name="name"
//                             type="text"
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             value={formik.values.name}
//                         />
//                         {formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}
//                     </div>
//
//                     <tr>
//                         <td className="col-4">Категория товара</td>
//                         <td className="col-8">
//                             <Field as="select" name="categoryIds" multiple
//                                    className="form-control form-control-lg">
//                                 <option value="" disabled>Выберите категории</option>
//                                 {categories && renderCategoryOptions(categories)}
//                             </Field>
//                         </td>
//                     </tr>
//
//                     <div>
//                         <label htmlFor="title">Title</label>
//                         <input
//                             id="title"
//                             name="title"
//                             type="text"
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             value={formik.values.title}
//                         />
//                         {formik.touched.title && formik.errors.title ? <div>{formik.errors.title}</div> : null}
//                     </div>
//                     <div>
//                         <label htmlFor="shortDescription">Short Description</label>
//                         <input
//                             id="shortDescription"
//                             name="shortDescription"
//                             type="text"
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             value={formik.values.shortDescription}
//                         />
//                         {formik.touched.shortDescription && formik.errors.shortDescription ?
//                             <div>{formik.errors.shortDescription}</div> : null}
//                     </div>
//                     <div>
//                         <label htmlFor="detailedDescription">Detailed Description</label>
//                         <textarea
//                             id="detailedDescription"
//                             name="detailedDescription"
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             value={formik.values.detailedDescription}
//                         />
//                         {formik.touched.detailedDescription && formik.errors.detailedDescription ?
//                             <div>{formik.errors.detailedDescription}</div> : null}
//                     </div>
//                     <div>
//                         <label htmlFor="metadescription">Meta Description</label>
//                         <input
//                             id="metadescription"
//                             name="metadescription"
//                             type="text"
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             value={formik.values.metadescription}
//                         />
//                         {formik.touched.metadescription && formik.errors.metadescription ?
//                             <div>{formik.errors.metadescription}</div> : null}
//                     </div>
//                     <div>
//                         <label htmlFor="imageUrl">Image URL</label>
//                         <input
//                             id="imageUrl"
//                             name="imageUrl"
//                             type="file"
//                             onChange={(event) => {
//                                 formik.setFieldValue("imageUrl", (event.currentTarget.files as FileList)[0]);
//                             }}
//                             onBlur={formik.handleBlur}
//                         />
//                         {formik.touched.imageUrl && formik.errors.imageUrl ? <div>{formik.errors.imageUrl}</div> : null}
//                     </div>
//                     <button type="submit" disabled={isLoading}>Submit</button>
//                     {error && <div>An error occurred: {(error as any).message}</div>}
//                     {isSuccess && <div>Article created successfully!</div>}
//                 </>
//             </form>
//         </Layout>
//     );
// };
//
// export default CreateArticleForm;