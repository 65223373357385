import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import Layout from 'components/Layout/Layout';

// const VisitsDetail = () => {

//     const { ipUserId } = useParams<{ ipUserId: string }>();
//     const [searchParams] = useSearchParams();
//     const date = searchParams.get('date') || new Date().toISOString().split('T')[0];
//     const [details, setDetails] = useState<string>('');
//     const [loading, setLoading] = useState<boolean>(false);

//     const fetchDetails = async () => {
//         setLoading(true);
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/migration/visit-details?ipUserId=${ipUserId}&date=${date}`);
//             const text = await response.text();
//             setDetails(text);
//         } catch (error) {
//             console.error('Ошибка при запросе:', error);
//             setDetails('Ошибка при загрузке данных.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchDetails();
//     }, [ipUserId, date]);

//     return (
//         <Layout>
//             <div>
//                 <h1>Детали посещений</h1>
//                 <label>
//                     Выберите дату:
//                     <input
//                         type="date"
//                         value={date}
//                         onChange={(e) => window.location.search = `?date=${e.target.value}`}
//                     />
//                 </label>
//                 {loading ? <p>Загрузка...</p> : <pre>{details}</pre>}
//             </div>
//         </Layout>
//     );
// };

// export default VisitsDetail;

const VisitsDetail = () => {
    const { ipUserId } = useParams<{ ipUserId: string }>();
    const [searchParams, setSearchParams] = useSearchParams();
    const initialDate = searchParams.get('date') || new Date().toISOString().split('T')[0];
    const ip = searchParams.get('ip'); // Получаем IP из параметров запроса
    const [date, setDate] = useState<string>(initialDate);
    const [details, setDetails] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const fetchDetails = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/migration/visit-details?ipUserId=${ipUserId}&date=${date}`);
            const text = await response.text();
            setDetails(text);
        } catch (error) {
            console.error('Ошибка при запросе:', error);
            setDetails('Ошибка при загрузке данных.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDetails();
    }, [ipUserId, date]);

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = e.target.value;
        setDate(newDate);
        setSearchParams({ date: newDate, ip: ip || '' }); // Обновляем дату и сохраняем ip в URL
    };

    return (
        <Layout>
            <div>
                <h1>Детали посещений IP: {ip}</h1> {/* Отображаем IP */}
                <label>
                    Выберите дату:
                    <input
                        type="date"
                        value={date}
                        onChange={handleDateChange}
                    />
                </label>
                {loading ? <p>Загрузка...</p> : <pre>{details}</pre>}
            </div>
        </Layout>
    );
};

export default VisitsDetail;