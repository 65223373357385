import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Layout from 'components/Layout/Layout';
import { useUpdateCategory } from 'hooks/categories/useUpdateCategory';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCategories } from 'hooks/categories/useCategories';
import { useCategory } from 'hooks/categories/useCategory';
import { Category } from 'types/Category';
import { Watch } from 'react-loader-spinner';
// import useAlert from 'hooks/useAlert';
import { useAlertContent } from 'context/AlertContext';
import AlertMessage from 'components/AlertMessage';
import { generateSlug } from "../../utils/generateSlug";
import { FormRow } from 'components/fragment/FormRow';

// Эти интерфейсы лучше вынести за пределы компонента
interface CategoryFormValues {
    categoryName: string;
    slug: string;
    seoTitle: string;
    seoDescription: string;
    isCommon: boolean;
    isActive: boolean;
    parentID?: number;
    imageURL?: string;
    description?: string;
    imageFile?: File | null;
}

const UpdateCategoryForm: React.FC = () => {

    const { triggerAlert } = useAlertContent();

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const categoryId = Number(id);

    const { data: fetchedCategoryData, isLoading: isCategoryLoading } = useCategory(categoryId.toString());
    const [formData, setFormData] = useState<CategoryFormValues | null>(null);
    const [file, setFile] = useState<File | null>(null);
    // const { alertData, showAlert, triggerAlert, resetAlert } = useAlert();
    const { mutate: updateCategory, isLoading: isUpdating } = useUpdateCategory();

    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const { data: categories, isLoading: isCategoriesLoading } = useCategories();


    const renderCategoryOptions = (categories: Category[], level = 0): React.ReactNode => {
        return categories.map((category) => (
            <React.Fragment key={category.id}>
                {category.id !== null && (
                    <option value={category.id !== null ? category.id : ''}>
                        {'-'.repeat(level)} {category.categoryName}
                    </option>
                )}
                {category.children && renderCategoryOptions(category.children, level + 1)}
            </React.Fragment>
        ));
    };


    useEffect(() => {
        if (fetchedCategoryData) {
            const initialData: CategoryFormValues = {
                categoryName: fetchedCategoryData.categoryName,
                slug: fetchedCategoryData.slug,
                seoTitle: fetchedCategoryData.seoTitle,
                seoDescription: fetchedCategoryData.seoDescription,
                isCommon: fetchedCategoryData.isCommon,
                isActive: fetchedCategoryData.isActive,
                parentID: fetchedCategoryData.parentID || '', // Установите значение по умолчанию, если parentID не существует
                imageURL: fetchedCategoryData.imageURL || '',
                description: fetchedCategoryData.description || '',
                imageFile: null, // Установите null для imageFile, как вы уже сделали
            };
            setFormData(initialData);
            setPreviewImage(fetchedCategoryData.imageURL || null);
        }
    }, [fetchedCategoryData]);

    const handleSubmit = (values: CategoryFormValues) => {
        if (!formData) return;

        const updateFormData = new FormData();
        updateFormData.append('category', JSON.stringify({
            ...values,
            parentID: values.parentID || null
        }));

        if (values.imageFile) {
            updateFormData.append('image', values.imageFile);
        }

        // Вывод данных в консоль
        console.log('Update Form Data:', updateFormData);
        console.log('values.imageFile:', values.imageFile);

        updateCategory({ formData: updateFormData, categoryId });
        // triggerAlert('Успешно обновлён!', 'success');
    };





    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newFile = event.currentTarget.files ? event.currentTarget.files[0] : null;
        setFile(newFile);
        setPreviewImage(newFile ? URL.createObjectURL(newFile) : null);
    };

    const handleRemoveImage = () => {
        setPreviewImage(null);
        setFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    if (isCategoryLoading || !formData) {
        return <div className="loader-spinner">
            <Watch
                visible={true}
                height="80"
                width="80"
                radius="48"
                color="#4fa94d"
                ariaLabel="watch-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>;
    }

    // if (isUpdating) {
    //     return <div className="loader-spinner">
    //         <Watch
    //             visible={true}
    //             height="80"
    //             width="80"
    //             radius="48"
    //             color="#4fa94d"
    //             ariaLabel="watch-loading"
    //             wrapperStyle={{}}
    //             wrapperClass=""
    //         />
    //     </div>;
    // }


    return (
        <Layout>
            <div>
                <h2>Обновить категорию</h2>
                <Link to="/show-category">Все категории</Link>
                <Formik initialValues={formData} onSubmit={handleSubmit} enableReinitialize={true}>
                    {({ setFieldValue, isSubmitting }) => (
                        <Form>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="col-4">Название категории</td>
                                        <td className="col-8">
                                            <Field
                                                className="form-control form-control-lg"
                                                type="text"
                                                name="categoryName"
                                                placeholder="Название категории"
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="col-4">Slug</td>
                                        <td className="col-8">
                                            <Field
                                                className={`form-control form-control-lg`}
                                                type="text"
                                                name="slug"
                                                placeholder="Slug"
                                            />
                                            <ErrorMessage name="slug" component="div" className="error" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="col-4">Seo Title</td>
                                        <td className="col-8">
                                            <Field
                                                className="form-control form-control-lg"
                                                type="text"
                                                name="seoTitle"
                                                placeholder="Seo Title"
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="col-4">Seo Description</td>
                                        <td className="col-8">
                                            <Field
                                                className="form-control form-control-lg"
                                                type="text"
                                                name="seoDescription"
                                                placeholder="Seo Description"
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="col-4">Общая категория</td>
                                        <td className="col-8">
                                            <Field
                                                className="form-check-input"
                                                type="checkbox"
                                                name="isCommon"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="col-4">Активная</td>
                                        <td className="col-8">
                                            <Field
                                                className="form-check-input"
                                                type="checkbox"
                                                name="isActive"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="col-4">ID родительской категории</td>
                                        <td className="col-8">
                                            <Field as="select" name="parentID" className="form-control form-control-lg">
                                                <option value="0">Выберите категорию</option>
                                                {categories && renderCategoryOptions(categories)}
                                            </Field>

                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td className="col-4">Описание</td>
                                        <td className="col-8">
                                            <Field
                                                className="form-control form-control-lg"
                                                as="textarea"
                                                name="description"
                                                placeholder="Описание"
                                            />
                                        </td>
                                    </tr> */}

                                    <FormRow
                                        label="Описание категории"
                                        type="textarea"
                                        name="description"
                                        placeholder="Описание категории"
                                        showHtmlToggle={true} // Включаем переключение между визуальным и HTML-редактором
                                    />

                                    <tr>
                                        <td className="col-4">Изображение категории</td>
                                        <td className="col-8">
                                            <div>
                                                {previewImage && (
                                                    <div className="d-flex">
                                                        <img src={previewImage} alt="Preview" style={{ width: '100px', height: '100px' }} />
                                                        <button type="button" onClick={handleRemoveImage} className="btn btn-danger btn-sm">
                                                            Удалить
                                                        </button>
                                                    </div>
                                                )}
                                                <input
                                                    id="file"
                                                    name="imageFile"
                                                    type="file"
                                                    ref={fileInputRef}
                                                    onChange={(event) => {
                                                        handleImageChange(event);
                                                        setFieldValue("imageFile", event.currentTarget.files ? event.currentTarget.files[0] : null);
                                                    }}
                                                    className="form-control"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button type="submit" className="btn btn-primary">
                                Обновить
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
            {isUpdating && (
                <div className="loader-spinner">
                    <Watch
                        visible={true}
                        height="80"
                        width="80"
                        radius="48"
                        color="#4fa94d"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
            {/* <AlertMessage
                message={alertData.message}
                type={alertData.type}
                showAlert={showAlert}
                onClose={resetAlert}
            /> */}
        </Layout>
    );
};

export default UpdateCategoryForm;