import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import axios from 'axios'; // Для запроса данных продукта
import { useUpdateProduct } from 'hooks/products/useUpdateProduct';
import { Link, useParams } from 'react-router-dom';
import { Attribute, ProductAttribute, ProductCategory } from 'types/ProductTypes';
import { useProduct } from './useProduct';
import Layout from 'components/Layout/Layout';
import { useCategories } from 'hooks/categories/useCategories';
import { Category } from 'types/Category';
import { ReactComponent as CrossIcon } from 'img/svg/cross-svgrepo-com.svg';
import AttributeFields from './attributes/AttributeFields';
import ProductAttributeFields from './attributes/ProductAttributeFields';
import { useGetAllAttributes } from 'hooks/products/useGetAllAttributes';
import { Watch } from 'react-loader-spinner';
import { useModal } from 'components/ConfirmModal';
import { storeStore } from "../../stores/StoreStore";
import { FormRow } from 'components/fragment/FormRow';


// Этот интерфейс должен соответствовать структуре данных атрибута внутри productAttributes
interface ProductAttributeExtended {
    attributeId: number;
    attributeName: string;
    attributeValue: string;
    categoryIds: number[];
}

interface AttributeValue {
    attributeId: number;
    value: string;
}


// Добавьте тип для параметров
interface InitialValues {
    productname: string;
    slug: string;
    canonicalUrl: string;
    is_active: boolean;
    sort_order: number;
    userId: number;
    storeId: number;
    categoryIds: number[];
    imageUrls: string[]; // Добавьте это свойство
    productAttributes: ProductAttributeExtended[];
    attributeValues: Record<string, { value: string }>;
    image_url?: string;
    price: number;
    description: string;
    seoTitle: string;
    seoDescription: string;
}

const UpdateProductForm = () => {
    const { showModal } = useModal();

    const { id } = useParams<{ id?: string }>();
    const productId = id ? parseInt(id) : null; // Обработка возможного undefined

    const { data: product, isLoading: isLoadingProduct, error: errorProduct, refetch } = useProduct(productId);
    const { data: attributes, error: errorAttributes, isLoading: isLoadingAttributes } = useGetAllAttributes();

    // console.log('product');
    // console.log(product);

    const [refreshKey, setRefreshKey] = useState(0);

    const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
    const [removedAttributes, setRemovedAttributes] = useState<number[]>([]);

    const [originalProductAttributes, setOriginalProductAttributes] = useState<ProductAttributeExtended[]>([]);

    const [mainImage, setMainImage] = useState<File | null>(null);

    const [activeTab, setActiveTab] = useState('characteristics');

    const handleTabClick = (tabName: React.SetStateAction<string>) => {
        setActiveTab(tabName);
    };


    const handleCategoryChange = (
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    ) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        const valueArray = Array.from(event.target.selectedOptions, option => parseInt(option.value));

        // Проверка на добавление категорий без удаления существующих
        const isAddingOnly = selectedCategoryIds.every(cid => valueArray.includes(cid));


        const attributesToRemove = initialValues.productAttributes.filter(pa =>
            // Проверяем, что у атрибута есть свойство categoryIds и оно не пустое
            pa.categoryIds && pa.categoryIds.length > 0 &&
            // Проверяем, что ни один из categoryIds атрибута не входит в список categoryIds из valueArray
            !pa.categoryIds.some((categoryId: number) => // Явно указываем тип categoryId
                valueArray.includes(categoryId) // Используем valueArray для проверки включения
            )
        );



        if (attributesToRemove.length > 0 && !isAddingOnly) {
            const attributeNames = attributesToRemove.map(attr => attr.attributeName).join(", ");
            // console.log('attributesToRemove');
            // console.log(attributesToRemove);

            showModal(
                `Вы точно хотите изменить категорию? Следующие атрибуты будут удалены:`,
                attributesToRemove.map(attr => attr.attributeName),
                () => {
                    // Подтверждение: Сохраняем ID атрибутов, которые будут удалены и продолжаем обработку
                    setRemovedAttributes(attributesToRemove.map(attr => attr.attributeId));

                    setFieldValue('categoryIds', valueArray);
                    setSelectedCategoryIds(valueArray);
                },
                () => {
                    // Отмена: Прекращаем обработку события
                    // console.log('Изменение категории отменено пользователем');
                }
            );
        } else {
            // Обновляем выбранные категории и значение в Formik, если нет атрибутов к удалению или добавляем категории
            setFieldValue('categoryIds', valueArray);
            setSelectedCategoryIds(valueArray);
        }
    };


    const handleProductUpdated = async () => {
        await refetch(); // Повторно получаем данные продукта
        setRefreshKey(oldKey => oldKey + 1); // Обновляем ключ для перерендера AttributeFields

        // Проверка на undefined перед использованием product
        if (product) {
            setInitialValues(prevValues => ({
                ...prevValues,
                productAttributes: product.productAttributes.map(pa => {
                    // Проверка на undefined перед использованием pa.attribute.id
                    const attributeId = pa.attribute.id ?? -1; // Пример использования запасного значения, например, -1
                    return {
                        attributeId,
                        attributeName: pa.attribute.attributeName,
                        attributeValue: pa.attributeValue,
                        categoryIds: pa.attribute.categoryIds ?? []
                    };
                })
            }));
        }

    };

    const { data: categories } = useCategories();

    // console.dir(product);


    // В компоненте
    const [currentImages, setCurrentImages] = useState<string[]>([]);
    const [removedImages, setRemovedImages] = useState<string[]>([]);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [mainImageUrl, setMainImageUrl] = useState<string | null>(null);
    const [removedMainImage, setRemovedMainImage] = useState<string>();


    // Синхронизация с внешним состоянием product.imageUrls
    useEffect(() => {
        if (product?.imageUrls) {
            setCurrentImages(product.imageUrls);
        }
    }, [product?.imageUrls]);

    useEffect(() => {
        if (product?.image_url) {
            setMainImageUrl(product.image_url);
        }
    }, [product?.image_url]);

    useEffect(() => {
        storeStore.fetchStoreByUserId();
    }, []);

    const renderStoreOptions = (currentStoreId: number) => {
        return storeStore.stores.map((store) => (
            <option key={store.id} value={store.id} selected={store.id === currentStoreId}>
                {store.storeName}
            </option>
        ));
    };



    const handleImageRemove = (imageIndex: number) => {
        if (currentImages) {
            const newImages = currentImages.filter((_, index) => index !== imageIndex);
            setCurrentImages(newImages); // Обновляем состояние, исключая удалённое изображение
            const removedImageUrl = currentImages[imageIndex];
            setRemovedImages(prev => [...prev, removedImageUrl]); // Добавляем URL удалённого изображения в список удалённых
        }
    };



    const handleMainImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {


        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setMainImage(file); // Сохраняем файл
            setMainImageUrl(URL.createObjectURL(file)); // Создаем и сохраняем URL для превью
            console.log("Выбран файл:", file.name);
        }

    };


    const renderCategoryOptions = (categories: Category[], level = 0): React.ReactNode => {
        return categories.map((category) => (
            <React.Fragment key={category.id}>
                <option value={category.id}>
                    {'-'.repeat(level)} {category.categoryName}
                </option>
                {category.children && renderCategoryOptions(category.children, level + 1)}
            </React.Fragment>
        ));
    };

    const renderMainImage = () => {
        if (!mainImageUrl) return null;  // Если URL изображения не установлен, не отображаем его

        return (
            <div className="image-preview">
                <img src={mainImageUrl} alt="Main Product Image" style={{ width: '100px', marginRight: '10px' }} />
                <button onClick={() => removeMainImage(mainImageUrl)} style={{ marginLeft: '10px' }}>Удалить</button>
            </div>
        );
    };



    const removeMainImage = (imageUrl: string) => {
        setRemovedMainImage(imageUrl);  // Сохранение URL изображения для удаления
        setMainImageUrl(null);  // Очистка текущего URL изображения

        // Очистка input элемента
        if (fileInputRef.current) {
            fileInputRef.current.value = "";  // Сброс выбранного файла
        }
    };

    // Измените renderCurrentImages, чтобы использовать currentImages вместо product.imageUrls
    const renderCurrentImages = (imageUrls: string[]) => {
        return (
            <div>
                {imageUrls.map((url, index) => (
                    <div key={index} className="image-preview">
                        <img src={url} alt={`Product Image ${index}`} style={{ width: '100px', marginRight: '10px' }} />
                        <CrossIcon className="cross" onClick={() => handleImageRemove(index)} />
                    </div>
                ))}
            </div>
        );
    };


    const [initialValues, setInitialValues] = useState<InitialValues>({
        productname: '',
        slug: '',
        canonicalUrl: '',
        is_active: true,
        sort_order: 0,
        userId: 1, // Предполагается, что это актуальный UserID
        storeId: 0,
        categoryIds: [],
        imageUrls: [],
        productAttributes: [],
        attributeValues: {},
        image_url: '',
        price: 0,
        description: '',
        seoTitle: '',
        seoDescription: '',
    });
    const [fileData, setFileData] = useState<FileList | null>(null);
    const { mutate: updateProduct, isLoading: isUpdating } = useUpdateProduct();

    // Не забудьте очистить URL при очистке компонента или удалении файла
    useEffect(() => {
        return () => {
            if (mainImageUrl) {
                URL.revokeObjectURL(mainImageUrl);
            }
        };
    }, [mainImageUrl]);

    useEffect(() => {
        // Загрузка данных продукта и установка их как начальных значений
        const fetchData = async () => {
            if (product) {
                const productAttributes = product.productAttributes.map(pa => ({
                    attributeId: pa.attribute.id!, // Утверждаем, что id не undefined
                    attributeName: pa.attribute.attributeName,
                    attributeValue: pa.attributeValue || '', // Устанавливаем пустую строку, если значение не определено
                    categoryIds: pa.attribute.categoryIds ?? []
                }));

                const productAttributesArray = product.productAttributes.map(pa => ({
                    // Убедитесь, что pa.attribute.id определен и является числом, иначе присвойте ему -1 или обработайте ошибку иначе
                    attributeId: typeof pa.attribute.id === 'number' ? pa.attribute.id : -1,
                    attributeName: pa.attribute.attributeName,
                    attributeValue: pa.attributeValue || '',
                    categoryIds: pa.attribute.categoryIds ?? []
                }));


                setInitialValues({
                    productname: product.productname,
                    slug: product.slug,
                    canonicalUrl: product.canonicalUrl,
                    is_active: product.is_active ?? true, // Используйте значение по умолчанию, если product.is_active undefined
                    sort_order: product.sort_order ?? 0, // Используйте значение по умолчанию, если product.sortOrder undefined
                    userId: product.userId ?? 1, // Замените 1 на актуальный userID или обработайте undefined
                    storeId: product.storeId ?? 1,
                    categoryIds: product?.categoryIds ?? [], // Предполагается, что product.categoryIds может быть undefined
                    imageUrls: product?.imageUrls ?? [],
                    // productAttributes,
                    productAttributes: productAttributesArray,
                    attributeValues: {},
                    image_url: product.image_url ?? '',
                    price: product.price,
                    description: product.description,
                    seoTitle: product.seoTitle,
                    seoDescription: product.seoDescription,
                });
                // console.log("initialValues.productAttributes:");
                // console.log(initialValues);
                // console.dir(productAttributesArray);
                setOriginalProductAttributes(productAttributes);
                setSelectedCategoryIds(product.categoryIds ?? []);
            }
        };

        fetchData();
    }, [productId, product]);


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFileData(event.target.files);
        }
    };

    const nonIncludedAttributes = attributes?.filter(attr =>
        !initialValues.productAttributes.some(pa => pa.attributeId === attr.id)
    );

    // const filterBySelectedCategories = (attr: { categoryIds?: number[] }) =>
    //     selectedCategoryIds.some(id => attr.categoryIds?.includes(id)
    //     );

    const filterBySelectedCategories = (attr: { categoryIds?: number[] }) =>
        selectedCategoryIds.length === 0 || // Пропускаем все атрибуты, если не выбрана ни одна категория
        !attr.categoryIds || // Пропускаем атрибут, если у него нет категорий
        attr.categoryIds.length === 0 || // Пропускаем атрибут, если у него пустой список категорий
        selectedCategoryIds.some(id => attr.categoryIds?.includes(id)); // Пропускаем атрибут, если его категория выбрана


    return (
        <Layout>
            <div>
                <h2>Обновление товара</h2>
                <Link to="/all-product">Все товары</Link>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize

                    onSubmit={(values, { setSubmitting }) => {
                        const formData = new FormData();

                        // Преобразование данных атрибутов и добавление в FormData
                        const finalProductData = {
                            ...values,
                            attributeValues: Object.entries(values.attributeValues)
                                .map(([key, attrValue]) => ({
                                    attributeId: parseInt(key, 10),
                                    value: attrValue.value,
                                }))
                        };
                        console.log("finalProductData");
                        console.log(finalProductData);
                        formData.append('productData', JSON.stringify(finalProductData));

                        if (fileData) {  // Добавление дополнительных изображений продукта
                            for (let i = 0; i < fileData.length; i++) {
                                formData.append('images', fileData[i]);
                            }
                        }

                        if (mainImage) {  // Добавление основного изображения продукта
                            formData.append('mainImage', mainImage);
                        }


                        if (removedImages.length > 0) {
                            formData.append('removedImages', JSON.stringify(removedImages));
                        }


                        formData.append('removedAttributes', JSON.stringify(removedAttributes));

                        // Добавляем информацию об удалении основного изображения
                        if (removedMainImage) {
                            formData.append('removedMainImage', removedMainImage);
                            setMainImage(null);
                        }

                        // console.log("removedImages: " + removedMainImage);
                        // console.log("useFormData: " + formData);

                        if (productId !== null) {
                            updateProduct({ productId, formData }, {
                                onSuccess: handleProductUpdated
                            });
                        }

                        setSubmitting(false);
                    }}


                >
                    {({ values, isSubmitting, setFieldValue }) => {
                        // console.log("Current form values:", values.productAttributes);
                        // console.log("nonIncludedAttributes: ");
                        // console.dir(nonIncludedAttributes);


                        return (
                            <Form>
                                <div>
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link ${activeTab === 'characteristics' ? 'active' : ''}`}
                                                onClick={() => handleTabClick('characteristics')}
                                            >
                                                Характеристики
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link ${activeTab === 'attributes' ? 'active' : ''}`}
                                                onClick={() => handleTabClick('attributes')}
                                            >
                                                Атрибуты
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link ${activeTab === 'seo' ? 'active' : ''}`}
                                                onClick={() => handleTabClick('seo')}
                                            >
                                                Seo
                                            </a>
                                        </li>
                                    </ul>


                                    <div className="tab-content">

                                        <div className={`tab-pane ${activeTab ===
                                            'characteristics' ? 'show active' : ''}`}>
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-4">Магазин</td>
                                                        <td className="col-8">
                                                            <Field as="select" name="storeId" className="form-control form-control-lg" value={values.storeId}>
                                                                <option value="" disabled>Выберите магазин</option>
                                                                {storeStore.stores.map((store) => (
                                                                    <option key={store.id} value={store.id}>
                                                                        {store.storeName}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td>Активный</td>
                                                        <td>
                                                            <div className="form-check form-switch">
                                                                <Field
                                                                    name="is_active"
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="is_active"
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="col-4">Основное изображение</td>
                                                        <td className="col-8">
                                                            {mainImageUrl && renderMainImage()}
                                                            <input
                                                                ref={fileInputRef}
                                                                className="form-control form-control-lg"
                                                                type="file"
                                                                name="mainImage"
                                                                onChange={handleMainImageChange}
                                                            />
                                                            {mainImage && (
                                                                <div style={{ marginTop: '10px' }}>
                                                                    <strong>Выбранное изображение:</strong> {mainImage.name}
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td className="col-4">Название товара</td>
                                                        <td className="col-8">
                                                            <Field className="form-control form-control-lg" type="text"
                                                                name="productname" placeholder="Название товара" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="col-4">Slug</td>
                                                        <td className="col-8">
                                                            <Field className="form-control form-control-lg" type="text"
                                                                name="slug" placeholder="Slug товара" />
                                                        </td>
                                                    </tr>

                                                    {/* <tr>
                                                        <td className="col-4">Описание товара</td>
                                                        <td className="col-8">
                                                            <Field
                                                                className="form-control form-control-lg"
                                                                as="textarea"
                                                                name="description"
                                                                placeholder="Описание товара" />


                                                        </td>
                                                    </tr> */}

                                                    <FormRow
                                                        label="Описание товара"
                                                        type="textarea"
                                                        name="description"
                                                        placeholder="Описание товара"
                                                        showHtmlToggle={true} // Включаем переключение между визуальным и HTML-редактором
                                                    />


                                                    <tr>
                                                        <td className="col-4">Текущие изображения</td>
                                                        <td className="col-8">
                                                            {currentImages && renderCurrentImages(currentImages)}
                                                            <input
                                                                className="form-control form-control-lg"
                                                                type="file"
                                                                name="images"
                                                                onChange={handleFileChange}
                                                                multiple
                                                            />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="col-4">Категория товара</td>
                                                        <td className="col-8">
                                                            <Field as="select" name="categoryIds" multiple
                                                                className="form-control form-control-lg"
                                                                onChange={handleCategoryChange(setFieldValue)}>
                                                                <option value="" disabled>Выберите категории</option>
                                                                {categories && renderCategoryOptions(categories)}
                                                            </Field>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-4">Сортировка</td>
                                                        <td className="col-8">
                                                            <Field className="form-control form-control-lg" type="text"
                                                                name="sort_order" placeholder="Сортировка" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="col-4">Цена</td>
                                                        <td className="col-8">
                                                            <Field className="form-control form-control-lg" type="number"
                                                                name="price" placeholder="Цена" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className={`tab-pane ${activeTab ===
                                            'attributes' ? 'show active' : ''}`}>
                                            <table className="table">
                                                <tbody>

                                                    {Object.entries(values.productAttributes)
                                                        .filter(([attributeId, attribute]) =>
                                                            selectedCategoryIds.length === 0 || // Если категории не выбраны, показываем все атрибуты
                                                            !attribute.categoryIds || // Если у атрибута нет категорий, показываем его
                                                            attribute.categoryIds.length === 0 || // Если список категорий атрибута пуст, показываем его
                                                            selectedCategoryIds.some(id => attribute.categoryIds.includes(id)) // Показываем атрибут, если его категория выбрана
                                                        )
                                                        .map(([attributeId, attribute]) => (
                                                            <tr key={attributeId}>
                                                                <td>{attribute.attributeName}</td>
                                                                <td>
                                                                    <Field
                                                                        name={`productAttributes.${attributeId}.attributeValue`}
                                                                        placeholder="Значение атрибута"
                                                                        className="form-control"
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                    {nonIncludedAttributes
                                                        ?.filter(filterBySelectedCategories)
                                                        .map((attr, index) => (
                                                            <tr key={attr.id}>
                                                                <td className="col-4">{attr.attributeName}</td>
                                                                <td className="col-8">
                                                                    <Field
                                                                        name={`attributeValues.${attr.id}.value`}
                                                                        type="text"
                                                                        placeholder={`Введите ${attr.attributeName}`}
                                                                        className="form-control form-control-lg"
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )) || <tr>
                                                            <td>Атрибуты не найдены.</td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={`tab-pane ${activeTab === 'seo' ? 'show active' : ''}`}>
                                            <table className="table">
                                                <tbody>

                                                    <tr>
                                                        <td className="col-4">Title</td>
                                                        <td className="col-8">
                                                            <Field className="form-control form-control-lg" type="text"
                                                                name="seoTitle" placeholder="seoTitle" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="col-4">Canonical</td>
                                                        <td className="col-8">
                                                            <Field className="form-control form-control-lg" type="text"
                                                                name="canonicalUrl" placeholder="canonicalUrl" />
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="col-4">Description</td>
                                                        <td className="col-8">
                                                            <Field className="form-control form-control-lg" type="text"
                                                                name="seoDescription" placeholder="Description" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                    Обновить товар
                                </button>
                            </Form>
                        );
                    }}
                </Formik>
                {(isUpdating) && (
                    <div className="loader-spinner">
                        <Watch
                            visible={true}
                            height="80"
                            width="80"
                            radius="48"
                            color="#4fa94d"
                            ariaLabel="watch-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default UpdateProductForm;
